import React from 'react';
import {Col, Input, ModalBody, Row} from "reactstrap";
import {useEntity} from "react-admin-base";
import {ModalEntityEditor} from "react-admin-base-bootstrap";
import {Field} from "../../components/Field";
import LanguageTab from "../../components/LanguageTab";
import LanguageInput from "../../components/LanguageInput";

export default function EquipmentEntity({url, onReload, id}) {
    const entity = useEntity('/equipment', id, { order: '1' });
    const [data, setData] = entity;

    if (!data) {
        return null;
    }

    return <ModalEntityEditor size="xl" title="Equipments" url={url} onReload={onReload} entity={entity}>
        <LanguageTab>
            <ModalBody>
                    <Row>
                        <Col md="2">
                            <Field translate="ORDER">
                                <Input
                                    type="number"
                                    value={data.order ?? ''}
                                    onChange={a => setData({
                                        order: a.currentTarget.value
                                    })}
                                />
                            </Field>
                        </Col>
                        <Col>
                            <Field translate="NAME" type="required">
                                <LanguageInput
                                    type="text"
                                    value={data.name}
                                    onChange={name => setData({ name })}
                                />
                            </Field>
                        </Col>
                    </Row>
            </ModalBody>
        </LanguageTab>
    </ModalEntityEditor>;
}
