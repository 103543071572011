import React from 'react';
import {Menu, MenuGroup, Sidebar} from 'react-admin-base-nazox';

export default function MenuSidebar() {
    return <Sidebar>
        <MenuGroup title="Admin">
            <Menu title="Languages" to="/language" icon="fas fa-flag"/>
            <Menu title="Skills" to="/skill" icon="fas fa-stairs"/>
            <Menu title="Equipments" to="/equipment" icon="fas fa-guitar"/>
            <Menu title="Activities" to="/activity" icon="fas fa-snowboarding"/>
        </MenuGroup>
        <MenuGroup title="Profile">
            <Menu title="Users" to="/user" icon="fas fa-users"/>
            <Menu title="My Profile" to="/profile" icon="fas fa-user"/>
        </MenuGroup>
    </Sidebar>;
}
