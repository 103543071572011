import {Input, InputGroup, InputGroupText} from "reactstrap";
import {useLanguageTab} from "./LanguageTab";
import {CKEditor} from "react-admin-base-ckeditor";

export default function LanguageInput(props) {
    const { value, onChange } = props;
    const language = useLanguageTab();

    return <InputGroup>
        <InputGroupText>{ language.toUpperCase() }</InputGroupText>
        <Input
            {...props}
            value={(value && value[language]) || ''}
            placeholder={value?.en}
            onChange={a => onChange({ ...(value), [language]: a.currentTarget.value }, language)}
        />
    </InputGroup>;
}

export function LanguageSpan({ text }) {
    const lang = useLanguageTab();

    return <span>
        { (text && text[lang]) || text?.en || '' }
    </span>;
}

export function MultiLangCKEditor({value, onChange}) {
    const lang = useLanguageTab();
    return <CKEditor
        value={(value && value[lang]) || value?.en || ''}
        onChange={a => onChange({...(value || {}), [lang]: a})}
    />;
}