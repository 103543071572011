import {CompactPicker} from "react-color";
import React from "react";

export default function ColorPicker({ value, onChange, disabled }) {
    return <div>
        <CompactPicker
            color={value && ('#' + value?.hex)}
            onChange={a => onChange({ hex: a.hex.replace(/^#/, '') })}
        />
    </div>;
}
