import React, {useCallback, useState} from 'react';
import {Header} from 'react-admin-base-nazox';
import {Link} from "react-router-dom";
import {LanguageSwitcher, useIsMobile} from "react-admin-base-bootstrap";
import {FormattedMessage} from "react-intl";
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import {useAuth} from "react-admin-base";
import {useUser} from "./providers/UserProvider";

export default function MenuHeader() {
    return <Header>
        <Link className="text-muted" to="/">
            Dashboard
        </Link>

        <div className="navbar-collapse collapse">
            <ul className="navbar-nav navbar-align justify-content-end">
                <LanguageSwitcher/>
                <UserPart />
            </ul>
        </div>
    </Header>;
}

function UserPart() {
    const user = useUser();
    const [api] = useAuth();
    const [show, setShow] = useState(false);
    const isMobile = useIsMobile();

    const logout = useCallback(function () {
        api.logout();
    }, [api]);

    return <Dropdown isOpen={show} toggle={() => setShow(!show)}>
        <DropdownToggle nav caret>
            { !isMobile && <><FormattedMessage id="HELLO"/>,</>}&nbsp;<b>{user.name ? user.name : user.email}</b>
        </DropdownToggle>
        <DropdownMenu end>
            { isMobile && <>
                <DropdownItem tag={Link} to="/">Portal</DropdownItem>
                <DropdownItem divider/>
            </> }
            <DropdownItem tag={Link} to="/profile">My Profile</DropdownItem>
            <DropdownItem divider/>
            <DropdownItem onClick={logout}>Çıkış</DropdownItem>
        </DropdownMenu>
    </Dropdown>;
}