import {ActionsColumn, Column, CRUD, CRUDActions, IdColumn, RowRenderer} from 'react-admin-base-bootstrap';
import {Breadcrumb} from 'react-admin-base-nazox';
import EquipmentEntity from "./EquipmentEntity";
import {LanguageSpan} from "../../components/LanguageInput";

export default function Equipments() {
    return <Breadcrumb
        title="Equipments"
        data={
            [
                {
                    href: '/equipment',
                    name: 'Equipments'
                }
            ]
        }
    >
        <CRUD url="/equipment" Component={EquipmentEntity}>
            <thead>
                <tr>
                    <IdColumn/>
                    <Column sort="order">Order</Column>
                    <Column>Name</Column>
                    <ActionsColumn/>
                </tr>
            </thead>
            <RowRenderer
                render={row => <tr key={row.id}>
                    <td className="min text-center">{row.id}</td>
                    <td className="min text-center">{row.order}</td>
                    <td><LanguageSpan text={row.name} /></td>
                    <CRUDActions
                        id={row.id}
                        edit
                        del={"/equipment/" + row.id}
                    />
                </tr>}
            />
        </CRUD>
    </Breadcrumb>;
}
