import React from 'react';
import {Col, Input, ModalBody, Row} from "reactstrap";
import {useEntity} from "react-admin-base";
import {ModalEntityEditor, SingleFilePicker} from "react-admin-base-bootstrap";
import {Field} from "../../components/Field";
import LanguageTab from "../../components/LanguageTab";
import LanguageInput from "../../components/LanguageInput";
import {EquipmentSelect, SkillSelect} from "../../components/Selects";
import GamePlaceSelect from "../../components/GamePlaceSelect";
import ColorPicker from "../../components/ColorPicker";
import GameTypeSelect from "../../components/GameTypeSelect";

export default function ActivityEntity({url, onReload, id, parent_id}) {
    const entity = useEntity('/activity', id, { order: '1', min_participants: 1, place: 1, color: { hex: '000000' } });
    const [data, setData] = entity;

    if (!data) {
        return null;
    }

    return <ModalEntityEditor size="xl" title="Activities" url={url} onReload={onReload} entity={entity}>
        <LanguageTab>
            <ModalBody>
                <Row>
                    <Col>
                        <Field title="Icon">
                            <SingleFilePicker
                                value={data.icon}
                                onChange={icon => setData({ icon })}
                                accepts="image/*"
                            />
                        </Field>
                    </Col>
                    <Col>
                        <Field title="Photo">
                            <SingleFilePicker
                                value={data.photo}
                                onChange={photo => setData({ photo })}
                                accepts="image/*"
                            />
                        </Field>
                    </Col>
                </Row>
                <Row>
                    <Col md="2">
                        <Field translate="ORDER">
                            <Input
                                type="number"
                                value={data.order ?? ''}
                                onChange={a => setData({
                                    order: a.currentTarget.value
                                })}
                            />
                        </Field>
                    </Col>
                    <Col>
                        <Field translate="NAME" type="required">
                            <LanguageInput
                                type="text"
                                value={data.name}
                                onChange={name => setData({ name })}
                            />
                        </Field>
                    </Col>
                </Row>
                <Field translate="VIDEO_URL" type="url">
                    <LanguageInput
                        value={data.video_url}
                        onChange={a => setData({video_url: a})}
                    />
                </Field>
                <Field translate="COLOR" type="required">
                    <ColorPicker
                        value={data.color}
                        onChange={a => setData({color: a})}
                        disabled={undefined}
                    />
                </Field>
                <Field title="Equipments">
                    <EquipmentSelect
                        value={data.equipments}
                        onChange={equipments => setData({ equipments })}
                        isMulti
                    />
                </Field>
                <Field title="Skills">
                    <SkillSelect
                        value={data.skills}
                        onChange={skills => setData({ skills })}
                        isMulti
                    />
                </Field>
                <Row>
                    <Col>
                        <Field title="Min. Participants">
                            <Input
                                type="number"
                                value={data.min_participants || ''}
                                onChange={a => setData({ min_participants: a.currentTarget.value })}
                            />
                        </Field>
                    </Col>
                    <Col>
                        <Field title="Max. Participants">
                            <Input
                                type="number"
                                value={data.max_participants || ''}
                                onChange={a => setData({ max_participants: a.currentTarget.value })}
                            />
                        </Field>
                    </Col>
                </Row>
                <Field title="Game Place">
                    <GamePlaceSelect
                        value={data.place}
                        onChange={place => setData({ place })}
                    />
                </Field>
                <Field title="Game Type">
                    <GameTypeSelect
                        value={data.type}
                        onChange={type => setData({ type })}
                    />
                </Field>
                <Field title="Description">
                    <LanguageInput
                        type="textarea"
                        value={data.description}
                        onChange={a => setData({ description: a })}
                        rows={3}
                    />
                </Field>
                <Field title="How to play?">
                    <LanguageInput
                        type="textarea"
                        value={data.how_to_play}
                        onChange={a => setData({ how_to_play: a })}
                        rows={10}
                    />
                </Field>
            </ModalBody>
        </LanguageTab>
    </ModalEntityEditor>;
}
