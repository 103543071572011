import './App.scss';

import {App, Authorized, AuthProvider, ForgotPasswordRoute, LoginRoute, NotAuthorized} from 'react-admin-base';
import {Login, MainLayout, Reset} from 'react-admin-base-nazox';

import MenuSidebar from "./MenuSidebar";
import Router from "./Router";
import MenuHeader from "./MenuHeader";
import Footer from "./Footer";
import {UserProvider} from "./providers/UserProvider";
import {BrowserRouter} from "react-router-dom";
import LanguageProvider from "./providers/LanguageProvider";
import UploadConfig from './providers/UploadConfig';

function BaseApp() {
    return (
        <BrowserRouter basename={process.env.REACT_APP_PATHBASE || undefined}>
            <App
                id="playlikekids"
                name="PlayLikeKids"
                endpoint={process.env.REACT_APP_ENDPOINT}
                logo={(process.env.REACT_APP_PATHBASE || "") + '/logo.png'}
                onlylogo={(process.env.REACT_APP_PATHBASE || "") + '/logo.png'}
                >
                <AuthProvider
                    tokenEndpoint="/token"
                    client_id="3da4385d-5166-4c1f-9c4b-49afe82be47e"
                    client_secret="123456"
                >
                    <LanguageProvider>
                        <NotAuthorized>
                            <LoginRoute>
                                <Login/>
                            </LoginRoute>
                            <ForgotPasswordRoute>
                                <Reset/>
                            </ForgotPasswordRoute>
                        </NotAuthorized>
                        <Authorized>
                            <UserProvider>
                                <UploadConfig>
                                    <MainLayout>
                                        <MenuHeader/>
                                        <MenuSidebar/>
                                        <Router/>
                                        <Footer/>
                                    </MainLayout>
                                </UploadConfig>
                            </UserProvider>
                        </Authorized>
                    </LanguageProvider>
                </AuthProvider>
            </App>
        </BrowserRouter>
    );
}

export default BaseApp;
