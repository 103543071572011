import React from 'react';
import {FormGroup, Input, ModalBody} from "reactstrap";
import {useEntity} from "react-admin-base";
import {ModalEntityEditor, PasswordInput} from 'react-admin-base-bootstrap';
import {useUser} from "../../providers/UserProvider";
import {Field} from '../../components/Field';

export const roles = {
    '512': 'Admin',
    '128': 'User'
};

export default function UserEntity({url, onReload, id}) {
    const user = useUser();
    const entity = useEntity('/user', id, {type: 512});
    const [data, setData] = entity;

    if (!data) {
        return null;
    }

    return <ModalEntityEditor title="User Management" url={url} onReload={onReload} entity={entity}>
        <ModalBody>
            <FormGroup>
                <Field translate="NAME_SURNAME" type="required">
                    <Input
                        type="text"
                        value={data.name}
                        onChange={a => setData({name: a.currentTarget.value})}
                    />
                </Field>
            </FormGroup>

            <FormGroup>
                <Field translate="EMAIL" type="required">
                    <Input type="text" value={data.email}
                           onChange={a => setData({email: a.currentTarget.value})}/>
                </Field>
            </FormGroup>

            <FormGroup>
                <Field translate="PASSWORD">
                    <PasswordInput value={data.password}
                           onChange={a => setData({password: a})}/>
                </Field>
            </FormGroup>
            <FormGroup>
                <Field translate="STATUS">
                    <Input
                        type="select"
                        value={data.type}
                        onChange={a => setData({type: +a.currentTarget.value, supplier: null})}
                    >
                        {Object.entries(roles).filter(([id]) => id <= user.type).map(([id, role]) => <option value={id}
                                                                                                             key={id}>{role}</option>)}
                    </Input>
                </Field>
            </FormGroup>
        </ModalBody>
    </ModalEntityEditor>;
}
