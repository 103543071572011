
import { ButtonGroup, Button } from 'reactstrap';

export default function GameTypeSelect({ value, onChange }) {
    return <ButtonGroup className="w-100">
        <Button color="primary" type="button" outline={!(value === 1)} onClick={a => onChange(1)}>
            Individual
        </Button>
        <Button color="primary" type="button" outline={!(value === 2)} onClick={a => onChange(2)}>
            Team
        </Button>
    </ButtonGroup>;
}
