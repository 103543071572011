import {Navigate, Route, Routes} from 'react-router-dom';

import Profile from "./pages/Profile/Edit";

import Users from "./pages/User/Users";
import Languages from "./pages/Language/Languages";
import Skills from "./pages/Skill/Skills";
import Equipments from "./pages/Equipment/Equipments";
import Activities from "./pages/Activity/Activities";

export default function Router() {
    return <Routes>
        <Route path="/language/*" element={<Languages/>}/>
        <Route path="/user/*" element={<Users/>}/>
        <Route path="/skill/*" element={<Skills/>}/>
        <Route path="/equipment/*" element={<Equipments />}/>
        <Route path="/activity/*" element={<Activities />}/>
        <Route path="/profile" element={<Profile/>}/>

        <Route path="*" element={<Navigate to="/user" replace/>}/>
    </Routes>;
}
