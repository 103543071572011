import {ActionsColumn, Column, CRUD, CRUDActions, IdColumn,  RowRenderer} from 'react-admin-base-bootstrap';
import {Breadcrumb} from 'react-admin-base-nazox';
import ActivityEntity from "./ActivityEntity";
import {LanguageSpan} from "../../components/LanguageInput";

export default function Activities() {
    return <Breadcrumb
        title="Activities"
        data={
            [
                {
                    href: '/activity',
                    name: 'Activities'
                }
            ]
        }
    >
        <CRUD url="/activity" Component={ActivityEntity}>
            <thead>
                <tr>
                    <IdColumn/>
                    <Column sort="order">Order</Column>
                    <Column>Name</Column>
                    <ActionsColumn/>
                </tr>
            </thead>
            <RowRenderer
                render={row => <tr key={row.id}>
                    <td className="min text-center">{row.id}</td>
                    <td className="min text-center">{row.order}</td>
                    <td><LanguageSpan text={row.name} /></td>
                    <CRUDActions
                        id={row.id}
                        edit
                        del={"/activity/" + row.id}
                    />
                </tr>}
            />
        </CRUD>
    </Breadcrumb>;
}
