import {
    Actions,
    ActionsColumn,
    Column,
    CRUD,
    ExcelExportButton,
    IdColumn,
    RowRenderer
} from 'react-admin-base-bootstrap';
import {Breadcrumb} from 'react-admin-base-nazox';
import React from 'react';
import UserEntity, {roles} from "./UserEntity";

export default function Users() {
    return <Breadcrumb
        title="Users"
        data={
            [
                {
                    href: '/user',
                    name: 'Users'
                }
            ]
        }
    >
        <CRUD url="/user" Component={UserEntity}>
            <thead>
            <tr>
                <IdColumn/>
                <Column sort="name">Name</Column>
                <Column sort="email">E-Mail Address</Column>
                <Column sort="type">Role</Column>
                <ActionsColumn/>
            </tr>
            </thead>
            <RowRenderer
                render={row => <tr key={row.id}>
                    <td className="min text-center">{row.id}</td>
                    <td>{row.name}</td>
                    <td>{row.email}</td>
                    <td>{roles[row.type]}</td>
                    <Actions
                        edit={"/user/" + row.id + '/edit'}
                        del={"/user/" + row.id}
                    />
                </tr>
                }
            />
            <>
                <ExcelExportButton
                    name="Users"
                    params={{
                        sort: 'id',
                        desc: false
                    }}
                    header={[
                        "ID",
                        "Name Surname",
                        "E-Mail-Address",
                        "Role",
                        "Creation Date"
                    ]}
                    map={row => [
                        row.id,
                        row.name,
                        row.email,
                        roles[row.type],
                        new Date(row.created_at)
                    ]} size={undefined} extra={undefined}                />
            </>
        </CRUD>
    </Breadcrumb>;
}
