import React from 'react';
import {Card, CardBody, Col, Input, Row} from "reactstrap";
import {useEntity} from "react-admin-base";
import {EntityEditor, PasswordInput} from 'react-admin-base-bootstrap';
import {Breadcrumb} from 'react-admin-base-nazox';
import {FormattedMessage} from "react-intl";
import {Field} from "../../components/Field";

export default function Edit() {
    const entity = useEntity('/user', 'me');
    const [data, setData] = entity;

    return <Breadcrumb
        title={<FormattedMessage id={"MY_PROFILE"}/>}
        data={
            [
                {
                    href: '/profil',
                    name: <FormattedMessage id={"MY_PROFILE"}/>
                }
            ]
        }>
        <EntityEditor entity={entity}>
            <Card>
                <CardBody>
                    <Row className="mb-3">
                        <Col>
                            <Field translate="NAME_AND_SURNAME" type="required">
                                <Input
                                    type="text"
                                    value={data.name}
                                    onChange={a => setData({name: a.currentTarget.value})}
                                />
                            </Field>
                        </Col>

                        <Col>
                            <Field translate="EMAIL" type="required">
                                <Input type="text" value={data.email}
                                       onChange={a => setData({email: a.currentTarget.value})}/>
                            </Field>
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <Col>
                            <Field translate="PASSWORD">
                                <PasswordInput
                                    value={data.password}
                                    onChange={a => setData({password: a})}
                                />
                            </Field>
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        </EntityEditor>
    </Breadcrumb>;
    ;
}
