import React from 'react';
import {ApiSelect, CreateSelect, Relative} from "react-admin-base-bootstrap";
import {LanguageSpan} from "./LanguageInput";
import SkillEntity from "../pages/Skill/SkillEntity";
import EquipmentEntity from "../pages/Equipment/EquipmentEntity";

export function LanguageSelect(props) {
    return <ApiSelect url="/language" {...props}>
        { row => <>
            { row.icon && <Relative><img style={{ width: '16px', height: '16px', marginRight: '5px' }} src={row.icon.access_url} alt={row.name} /></Relative> }
            { row.name }
        </>}
    </ApiSelect>;
}

export function UserSelect(props) {
    return <ApiSelect
        url="/user"
        {...props}
    />;
}

export function SkillSelect(props) {
    return <CreateSelect
        url="/skill?itemPerPage=-1&sort=order&desc=false"
        filter={() => true}
        Component={SkillEntity}
        {...props}
    >
        { row => <>
            <LanguageSpan text={row.name} />
        </> }
    </CreateSelect>;
}

export function EquipmentSelect(props) {
    return <CreateSelect
        url="/equipment?itemPerPage=-1&sort=order&desc=false"
        filter={() => true}
        Component={EquipmentEntity}
        {...props}
        >
        { row => <>
        <LanguageSpan text={row.name} />
        </> }
    </CreateSelect>;
}
