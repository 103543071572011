import React, {useCallback} from 'react';
import {UploadProvider, useAuth} from "react-admin-base";

export default function UploadConfig({children}) {
    const [api] = useAuth();

    const uploader = useCallback(async function (name, blob, contentType, abort, progress) {
        try {
            const data = await api.tokenized.put('/file/' + encodeURIComponent(name), blob, {
                headers: {'Content-Type': contentType},
                onUploadProgress(progressEvent) {
                    progress(progressEvent.loaded / progressEvent.total);
                },
                signal: abort
            });

            let file = data.data;

            file.access_url = process.env.REACT_APP_ENDPOINT.replace(/\/$/, '') + data.data.access_url;

            return file;
        } catch (e) {
            //  if (!Axios.isCancel(e)) {
            //     console.error('Hata olustu, tekrar deneniyor..', e);
            //} else {
                console.log("Es wird abgesagt");
            // }

            throw e;
        }
    }, [api]);

    return <UploadProvider uploader={uploader}>
        {children}
    </UploadProvider>
}
