import {createContext, useContext, useState} from "react";
import {useLanguage} from "react-admin-base-bootstrap";
import {Nav, NavItem, NavLink} from "reactstrap";

const LanguageTabContext = createContext('');

export default function LanguageTab({ className = '', children }) {
    const [ active, languages ] = useLanguage();
    const lang = useLanguageTab();
    const [ activeLang, setActiveLang ] = useState(lang);

    return <LanguageTabContext.Provider value={activeLang}>
        { languages && <Nav className={'d-flex nav-tabs-custom flex-row ' + (className || '')} tabs>
            { [...Object.values(languages)].sort((a, b) => a.priority - b.priority).map(lang => <NavItem className="flex-grow-1 text-center"  key={lang.id}>
                <NavLink className="py-3 px-3" href={"#" + lang.language_code} active={lang.language_code === activeLang} onClick={e => {e.preventDefault(); setActiveLang(lang.language_code); }}>
                    { lang.icon && <img style={{ width: '16px', height: '16px', marginRight: '5px' }} src={lang.icon} alt={lang.name} /> }
                    {lang.name}
                </NavLink>
            </NavItem>) }
        </Nav> }
        { children }
    </LanguageTabContext.Provider>;
}

export function useLanguageTab() {
    const [ lang ] = useLanguage();
    return useContext(LanguageTabContext) || lang.language_code;
}
